import axios from "axios"
import camelCaseKeys from "camelcase-keys"

const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

// レスポンスをsnake_caseからcamelCaseに変換する
const fetcher = axios.create({
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken
  },
  responseType: "json"
})

fetcher.interceptors.response.use((response) => {
  return camelCaseKeys(response, { deep: true })
})

export { fetcher }
