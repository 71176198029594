import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// モーダルHTMLにアタッチさせると読み込み時にモーダルを起動させる
// Connects to data-controller="modal--open"
export default class extends Controller {
  connect() {
    this.modal = Modal.getOrCreateInstance(this.element)
    this.modal.show()
  }
}
