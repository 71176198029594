// 出品者メニュー 注文管理画面用

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="seller-orders"
export default class extends Controller {
  // 画面に初期表示したパラメータの値
  static values = {
    phase: String,
    sortKey: String,
    isSellerPhase: Boolean
  }

  /**
   * 並び替えを選択すると検索パラメータ付きリクエストを送信する
   * event.target.value: セレクトボックスで選択された値
   * this.phaseValue: data-seller-orders-phase-value="transaction" フィルターの値
   */
  selectSortOrder(event) {
    this.sortKeyValue = event.target.value;
    this.requestOrders();
  }

  /**
   * チェックボックスの表示切替、検索パラメータ付きリクエストを送信する
   */
  toggleSellerPhase(event) {
    event.target.parentNode.classList.toggle("is_current");
    event.target.parentNode.parentNode.classList.toggle("is_current");
    this.isSellerPhaseValue = event.target.checked;
    this.requestOrders();
  }

  requestOrders() {
    // パラメータをセットして Turbo でリクエスト
    const url = `/seller/orders?sort_key=${this.sortKeyValue}&phase=${this.phaseValue}&is_seller_phase=${this.isSellerPhaseValue}`;
    Turbo.visit(url, { frame: 'order_manage_frame' });
  }
}
