import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="seller-orders-cancel"
export default class extends Controller {
  static targets = ['check', 'content', 'cancelText', 'cancelBtn', ]

  // フォームの「支払いがないことを確認しました」チェックボックスの挙動
  connect() {
    this.toggleCancelContent()
  }

  // チェックボックスにチェックが入ったらtextareaをアクティブに。テキストエリアが空でなければキャンセルボタンをアクティブに。
  toggleCancelContent() {
    if ( this.checkTarget.checked == false ) {
      this.contentTarget.classList.add('is_disabled')
      this.cancelTextTarget.disabled = true
      this.cancelBtnTarget.disabled = true
    } else {
      this.contentTarget.classList.remove('is_disabled')
      this.cancelTextTarget.disabled = false
      if ( this.cancelTextTarget.value != "" ){
        this.cancelBtnTarget.disabled = false
      }
    }
  }
}
