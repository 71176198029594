import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
// Connects to data-controller="underage-confirm"
export default class extends Controller {
  static targets = ['redirectPath'];

  connect() {
    this.underageConfirmShow();
  }

  underageConfirmShow() {
    const targetPathField = document.getElementById("targetPath");
    targetPathField.value = this.redirectPathTarget.value;

    const underage_modal = new Modal(document.getElementById("underageModal"));
    underage_modal.show();
  }
}