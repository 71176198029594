// 出品者メニュー 商品管理画面用

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="seller-products"
export default class extends Controller {
  static values = {
    filter: String
  }

  /**
   * 並び替えを選択するとフォームを送信する
   * event.target.value: セレクトボックスで選択された値
   * this.filterValue: data-seller-products-filter-value="soldout" フィルターの値
   */
  selectSortOrder(event) {
    // パラメータをセットして Turbo でリクエストする
    const url = `/seller/products?sort=${event.target.value}&filter=${this.filterValue}`;
    Turbo.visit(url, { frame: 'product_manage_frame' })
  }
}
