import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="agreement"
export default class extends Controller {
  static targets = ['check']

  // フォームの「同意する」チェックボックスの挙動
  connect() {
    this.toggleRegistrationBtn()
  }

  toggleRegistrationBtn() {
    const registrationBtn = document.querySelector('.registrationBtn')
    if ( this.checkTarget.checked == false ) {
      registrationBtn.disabled = true
    } else {
      registrationBtn.disabled = false
    }
  }
}
