//
// 【共通処理】Turbo frame の更新を検知してModalを閉じる
// このコントローラをアタッチするHTMLタグのdata属性で .modal に付与したID属性の値を指定することで任意のモーダルを操作する
// 対象となる modalのIDは data-modal--close-modal-ids-value='[]' に配列で記載する
//
import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap";

// Connects to data-controller="modal--close" data-modal--close-modal-ids-value='[ "modal_pay", "modal_pay_del" ]'
export default class extends Controller {
  static values = {
    modalIds: Array // .modal に割り当てたID属性の値を配列で複数受け取り可
  }

  connect() {
    this.modalIdsValue.forEach(idName => {
      this.closeModal(idName)
    })
  }

  closeModal(idName) {
    // モーダルのフォームをsubmit後、変更を反映するために turbo-frame が更新されるタイミングでモーダルが起動していれば閉じる
    const modalElm = document.getElementById(idName)
    if (modalElm) {
      this.modal = Modal.getOrCreateInstance(modalElm)
      this.modal.hide()
    }
  }
}
