import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="basic-settings"
export default class extends Controller {
  connect() {
    this.loadCancelPolicy();
    this.slideUpMessage();
  }

  /**
   *基本情報の保存完了バナー
   */
  slideUpMessage(){
    // 4秒後に非表示に
    setTimeout(() => {
      $(".success_text").slideUp();
    }, 4000);
  }

  /**
   *画面ロード時のキャンセルポリシー制御
   */
  loadCancelPolicy(){
    const cancelPolicy = document.getElementById("furima_basic_setting_cancel_policy");
    const elemBasePolicy = document.getElementById("basePolicy");
    const elemOriginPolicy = document.getElementById("originPolicy");
    const elemCancelPolicyTextArea = document.getElementsByClassName("js_cancelPolicy_textArea");
    const cancelPolicyCode = document.getElementById("furima_basic_setting_cancel_policy_code");

    $('.js_cancelPolicy_list li').removeClass('is_current');

    //キャンセルポリシー選択
    if(cancelPolicyCode.value == 'originPolicy' || cancelPolicy.value != ""){
      //独自キャンセルポリシーを選択
      //画面初期表示時、DBにキャンセルポリシー設定時
      //バリデート後のリロード時、独自キャンセルポリシー選択時

      $(elemOriginPolicy).parents('div').parents('li').addClass('is_current');
      elemOriginPolicy.checked = true;

      cancelPolicyCode.value = 'originPolicy';
      $(elemCancelPolicyTextArea).slideDown();
    }else{
      //基本キャンセルポリシーを選択
      $(elemBasePolicy).parents('div').parents('li').addClass('is_current');
      elemBasePolicy.checked = true;
      cancelPolicyCode.value = 'basePolicy';
      $(elemCancelPolicyTextArea).slideUp();
    }
}

  /**
   * 基本情報の管理画面ーキャンセルポリシー
   */
  // 選択されたキャンセルポリシーの外枠のクラスを追加
  changeCancelPolicy() {
    const cancelPolicyCode = document.getElementById("furima_basic_setting_cancel_policy_code");
    $('input[name="cancelPolicy"]').on('change', function () {
      // 全てのクラスを外す
      $('.js_cancelPolicy_list li').removeClass('is_current');
      // checked状態かどうか判断
      if ($(this).is(':checked')){
        // TRUE:枠線用クラス追加
        $(this).parents('li').addClass('is_current');
      };
      // キャンセルポリシー入力欄の表示非表示
      if ($('.js_originPolicy').prop('checked')) {
        cancelPolicyCode.value = 'originPolicy';
        $('.js_cancelPolicy_textArea').slideDown();
      } else {
        cancelPolicyCode.value = 'basePolicy';
        $('.js_cancelPolicy_textArea').slideUp();
      }
    });
  }
}
