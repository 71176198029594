import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products-list"
export default class extends Controller {
  /**
   * 並び替えを選択するとフォームを送信する
   *
   */
  sort() {
    this.setSearchParams();
    // ProductListFilterController#searchProducts をコール
    const event = new CustomEvent('search_products')
    window.dispatchEvent(event);
  }

  setSearchParams() {
    const hiddenSort = document.getElementById("hiddenSort");
    const sortSelect = document.getElementById("sortSelect");
    hiddenSort.value = sortSelect.value;
  }
}
