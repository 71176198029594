// app/helpers/turbo_streams/redirect_helper.rb とセットで turbo フレームからリダイレクトで全画面書き換え時に利用する
// Rails コントローラで以下のように記述することで正しくリダイレクトすることができる
//   e.g.) render turbo_stream: turbo_stream.redirect(root_path)
const { StreamActions } = Turbo

Turbo.StreamActions.redirect = function () {
  // 画面が切り替わる直前に余計なモーダルが表示されることがあるのでキャッシュをクリア
  Turbo.cache.clear()

  const url = this.getAttribute('url') || '/'
  Turbo.visit(url, { frame: '_top', action: 'advance' })
}
