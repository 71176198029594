import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sending"
export default class extends Controller {
  static targets = ["loadingScreen"];

  initialize() {
    this.element[this.identifier] = this;
  }

  submit() {
    this.loadingScreenTarget.style.display = "block";
  }
}
