import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle";

// Connects to data-controller="template-addition"
export default class extends Controller {
  connect() {
    this.productDetail()
    this.updateReviewTextHeight();
    this.updateReviewTextHeight = this.updateReviewTextHeight.bind(this);
    window.addEventListener("resize", this.updateReviewTextHeight);
  }

  // mock: assets/js/product.js
  productDetail() {
    // 商品説明
    // 親要素ボックス
    const descriptionBox = $('.productDescription');
    // 詳細説明ボックス
    const descriptionText = $('.productDescription .productDescription_inner');
    // 詳細説明ボックス高さ
    const descriptionHeight = descriptionText.height();

    // 360px以上の高さだった場合、親要素ボックスに.is_accordionを付ける。「続きを読む」「閉じる」の対応の為。
    if(descriptionHeight >= 360){ // 高さ360px
      descriptionBox.addClass('is_accordion');
    }

    // 質問コメント
    // 読み込み時に吹き出しの高さを計測。4行以上の高さだったら.is_accordionを付ける。「続きを読む」「閉じる」の対応の為。
    $('.comment_box .commentText').each(function() {
      const height = $(this).height();
      const $comment = $(this).parents('.comment');
      if(height >= 120){ // 高さ120pxを4行とする
        $comment.addClass('is_accordion');
      }
    })

    // 吹き出しの「続きを読む」「閉じる」の対応
    $('.comment_box .comment .more, .comment_box .comment .close').on('click', function() {
      const $comment = $(this).closest('.is_accordion');
      const $commentText = $(this).siblings('.commentText');
      const commentTextheight = $commentText.get(0).scrollHeight;
      // is_activeを付け外し
      $comment.toggleClass('is_active');
      // 吹き出しが開くときのアニメーション用にmax-heightを付け外し
      if($comment.hasClass('is_active')) {
        // is_activeが付いたときの吹き出しのpadding-bottomの値を取得
        const commentTextheightPaddingBottom = Number($commentText.css('paddingBottom').replace(/px/, ''));
        $commentText.css('maxHeight', commentTextheight + commentTextheightPaddingBottom);
      } else {
        $commentText.css('maxHeight', '');
      }
      // 「コメントをもっと見る」ボタンでコメントたちが全数表示になっている時、heightをautoで上書き。吹き出しが広がりきらない為。
      $(this).closest('.overComment.is_active').height('auto');
      // リンク先に飛ばないように止める
      return false;
    });

    // コメント数が多いときに「コメントをもっと見る」ボタンを出して制御
    const $commentListLi = $('.commentList li');
    const commentListLiShowLength = 3; //最初に表示しておくコメント数
    if($commentListLi.length > commentListLiShowLength) {
      $('.commentShowAllBtn_box').show();
    }
    // 多いコメントをclassを付けて隠す
    $commentListLi.each(function(index){
      if(index < commentListLiShowLength ) {
        return true;
      }
      $(this).addClass('overComment');
    })

    // 「コメントをもっと見る」ボタンを押した対応
    $('.commentShowAllBtn_box button').on('click', function() {
      // 隠していたコメントを表示
      const $overComment = $('.commentList .overComment');
      $overComment.toggleClass('is_active');
      // コメントが開くときのアニメーション用にheightを付け外し
      $overComment.each(function(){
        if($(this).hasClass('is_active')){
          const overCommentheight = $(this).get(0).scrollHeight;
          $(this).height(overCommentheight);
        } else {
          $(this).height('');
        }
      })
      // 「コメントをもっと見る」ボタンを入れ替え
      $('.commentShowAllBtn_box').toggleClass('is_active');
    });

    // 商品レビュー「続きを読む」ボタン//
    // 対象テキスト
    const reviewText = $('.js_reviewText');
    // 続きを読むボタン
    const reviewMoreBtn = $('.js_reviewMoreBtn');
    // 設定高さ
    const baseHeight = 100;

    // 「続きを読む」「閉じる」の対応
    $(reviewMoreBtn).click(function() {
      let originalHeight = $(this).siblings(reviewText).css({
        'height': '',
      }).innerHeight();

      let parentElement = $(this).parent();

      parentElement.toggleClass('is_active');
      if($(this).hasClass('is_open')){
        $(this).removeClass('is_open');
        $(this).text('続きを読む');
        parentElement.css({
          'max-height': baseHeight,
          'overflow': 'hidden',
        });
      } else {
        $(this).addClass('is_open');
        $(this).text('閉じる');
        parentElement.css({
          'max-height': originalHeight,
          'overflow': 'visible',
        });
      }
    });

    // 商品写真モーダル
    $(function () {
      let swiper
      let swiperThumbnail

      if (window.matchMedia('(max-width: 768px)').matches) {
        /* ウィンドウサイズ768以下の処理を記述
        const productImgSpSlideThumbnail = new Swiper(".productImgSpSlide_thumbnail", {
          // サムネイルの枚数
          slidesPerView: 10,
        }); */

        const productImgSpSlide = new Swiper(".productImgSpSlide", {
          loop: true,
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },/*
          thumbs: {
            swiper: productImgSpSlideThumbnail,
          },*/
        })
      };

      // モーダルを開く動作
      $('.js-productImgModalOpen').click(function(){

        swiperThumbnail = new Swiper(".productImgModal .swiperThumbnail", {
          // サムネイルの枚数
          slidesPerView: 10,
        });

        swiper = new Swiper('.productImgModal .swiperMain', {
          loop: true,
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          thumbs: {
            swiper: swiperThumbnail,
          },
        });

        let index
        const index_pc = $(this).data('index'); // クリックされた画像のインデックスを取得
        const index_sp = $(".swiper-slide-active").data('swiper-slide-index'); // （SP用）表示している画像のインデックスを取得
        if (typeof index_pc !== 'undefined') {
          index = index_pc
        }else{
          index = index_sp
        }
        swiper.slideTo(index, 0, false); // 指定されたスライド（画像）へ移動

        $('.js-productImgModal').fadeIn();
        $('body').css('overflow-y', 'hidden');
      });

      $('.js-productImgModalClose, .js-productImgModal').click(function () {
        $('.js-productImgModal').css('opacity', 0);

        // Swiperのインスタンスを破棄
        if (swiper) {
          swiper.destroy(true, true);
          swiper = null;
        }
        if (swiperThumbnail) {
          swiperThumbnail.destroy(true, true);
          swiperThumbnail = null;
        }
        $('.js-productImgModal').fadeOut(function() {
          $('.js-productImgModal').css('opacity', 1);
        });
        $('body').css('overflow-y','auto');
      });

      $('.js-productImgModalClickArea').on('click', function (e) {
        e.stopPropagation();
      });

    });
  }

  updateReviewTextHeight(){
    // 対象テキスト
    const reviewText = $('.js_reviewText');
    // 続きを読むボタン
    const reviewMoreBtn = $('.js_reviewMoreBtn');
    // 設定高さ
    const baseHeight = 100;
    reviewText.each(function() {
      // テキスト全文の高さ
      let reviewTextHeight = $(this).height();
      // 設定高さより少ないときはもっと見るボタン非表示
      if(reviewTextHeight <= baseHeight){
        $(this).siblings(reviewMoreBtn).hide();
      } else {
        $(this).addClass('is_accordion');
      }
    });
  }

}
