import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-form"
export default class extends Controller {
  static targets = ['paymentMethodIds']

  connect() {
    this.changeForm()

    // 選択済み支払先IDの取得（出品フォーム用）
    this.setPaymentMethodIds()
  }

  /**
   * 支払い方法選択時のビュー切り替え
   *
   */
  changeForm() {
    const payMethodValue = document.getElementById("payMethod").value

    // 全ての詳細入力フォームを非表示にし、選択されたフォームのみ再表示
    this.toggleDisplayByClassName('.sell_pay', false)
    if (payMethodValue === "bank") this.toggleDisplayByClassName(".sell_pay_bank", true)
    if (payMethodValue === "online") this.toggleDisplayByClassName(".sell_pay_online", true)
  }

  toggleDisplayByClassName(taergetClass, isDiplay) {
    document.querySelectorAll(taergetClass).forEach(function(elm) {
      // inputタグのdisabledを切り替え
      const inputItem = elm.getElementsByTagName("input");
      for(var i=0; i<inputItem.length; i++){
        inputItem[i].disabled = !isDiplay;
      }
      // フォームの表示を切り替え
      if (isDiplay) {
        elm.classList.remove("d-none")
      } else {
        elm.classList.add("d-none")
      }
    })
  }

  // モーダル起動時、支払先登録フォームに選択済みの payment_method_id を保持する
  // Railsへ送信し、支払先リストを再描画するときにチェック状態を復元する
  setPaymentMethodIds() {
    if(!this.paymentMethodIdsTarget) return

    const checkedPay = document.querySelectorAll('.payment_method_id:checked')
    let payment_method_ids = []
    for (const pay of checkedPay) {
      payment_method_ids.push(pay.value)
    }
    this.paymentMethodIdsTarget.value = payment_method_ids
  }
}
