import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="category-link"
export default class extends Controller {
  // カテゴリメニューの開閉
  toggleMenu(event) {
    // クリックされたのが大カテゴリ名の場合に動作させ、サブカテゴリ名の場合は何もせずにリンクで画面遷移させる
    if (event.target.classList.contains('toggle_menu')) {
      event.currentTarget.classList.toggle('is_open');
      event.currentTarget.querySelectorAll('div.sub_cat_inner')[0].classList.toggle('is_open');
    }
  }
}
