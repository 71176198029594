import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle";

// Connects to data-controller="review"
export default class extends Controller {
  static targets = [ "modal","comment","comment_confirm","images","images_confirm","no_comment","no_image"];
  connect() {
  }

  /**
   * 確認フォームへの切り替え
   */
  chgConfirm(){
    this.modalTarget.classList.toggle("is_confirm");
    this.comment_confirmTarget.innerText = this.commentTarget.value;
    if(this.commentTarget.value == ""){
      this.no_commentTarget.style.display  = "block";
    }else{
      this.no_commentTarget.style.display  = "none";
    }
  }
}
