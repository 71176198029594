import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-payment"
export default class extends Controller {

  // お支払い方法選択ラジオボタン onChange
  // 支払い完了通知モーダルのセレクトボックスの selected を設定
  selectPayId({ params: { id } }) {
    document.getElementById("payMethod").querySelector(`option[value='${id}']`).selected = true
    this.displayPaymentSection(id);
  }

  // 表示する「お支払い先」のセクションを切り替え
  displayPaymentSection(id) {
    const sections = document.querySelectorAll('.payment_section');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('is_active');
    }
    document.getElementById(`pay_detail_${id}`).classList.add("is_active");
  }
}
