import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="transaction-histories-cancel"
export default class extends Controller {

  connect() {
  }

  scrollToSendMsg(){
    const scrollInterval = setInterval(function() {
      const target = document.getElementById('sendMsg');
      if (target) {
        // 取引メッセージまでスクロール
        target.scrollIntoView({ behavior: 'smooth' });
        clearInterval(scrollInterval);

        // モーダルを閉じる
        Modal.getOrCreateInstance(document.getElementById('modal_cancel')).hide();
      }
    }, 500);
  }
}
