const Common = {
  /**
   * チェックボックスの制御（スタイル）
   */
  changeCheckboxStyle(id) {
    const checkbox = document.getElementById(id);
    checkbox.parentNode.classList.toggle("is_current");
    checkbox.parentNode.parentNode.classList.toggle("is_current");
  }
};

document.addEventListener("DOMContentLoaded", () => {
  // ページロード後にグローバル定数にCommonを追加する
  Object.defineProperty(globalThis, "Common", {
    value: Common,
    writable: false,
  });
});

// Turbo DriveではDOMContentLoadedの代わりにturbo:loadを利用できる
// 最初のページの読み込み時に1回発生して、あとはTurbo Driveで画面遷移する度に発生する
document.addEventListener("turbo:load", function() {
  /**
   *基本情報の保存完了バナー
   */

  // クラスが付与されたら表示
  if ($(".success_text").hasClass("is_active")) {
    $(".success_text").slideDown();

    // 4秒後に非表示に
    setTimeout(() => {
      $(".success_text").slideUp();
    }, 4000);
  }

  /**
   * スクロール量に応じた制御
   */

  let setPosition = 0;
  $(window).on("scroll", function () {
    if (setPosition < $(window).scrollTop()) {
      $(".js_seller_menu").removeClass("is_active");
    } else {
      $(".js_seller_menu").addClass("is_active");
    }
    setPosition = $(window).scrollTop();
  });

  /**
   *ステータスバー
   */

  // クラスが付与されたら表示
  if ($(".statusbar").hasClass("is_active")) {
    $(".statusbar").slideDown();
    // 10秒後に非表示に
    setTimeout(() => {
      $(".statusbar").slideUp();
    }, 10000);
  }
})

$(function () {
  //発送通知用
  const cancelTextSend = $(".cancel_text_send");
  const cancelTextValueSend = $(".present_value_send");
  const overTextSend = $(".over_text_send");
  const cancelBtnSend = $(".cancel_send");

  cancelTextSend.on("keydown keyup keypress paste change", function (e) {
    const value = e.target.value; // 入力された文字
    const count = value.length; // 入力された文字数
    cancelTextValueSend.text(count); // 入力文字数確認用(更新/発送通知用)

    // 140文字到達時警告
    if (count > 140) {
      overTextSend.addClass("is_active");
      cancelTextValueSend.addClass("is_active");
      cancelTextSend.addClass("is_error");
      cancelBtnSend.prop("disabled", true);
    } else {
      overTextSend.removeClass("is_active");
      cancelTextValueSend.removeClass("is_active");
      cancelTextSend.removeClass("is_error");
      cancelBtnSend.prop("disabled", false);
    }
  });
});
