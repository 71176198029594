import { Controller } from "@hotwired/stimulus"

const TOOLTIP_TEXT_REMOVE = 'お気に入りから削除';

// Connects to data-controller="comment"
export default class extends Controller {
  connect() {
    this.commentEvent()
    this.updateReviewTextHeight();
    this.updateReviewTextHeight = this.updateReviewTextHeight.bind(this);
    window.addEventListener("resize", this.updateReviewTextHeight);

  }

  commentEvent() {
    // 注文キャンセルモーダルの文字制御
    const cancelText = $(".cancel_text");
    const cancelText140 = $(".limit_text_140");
    const cancelText1000 = $(".limit_text_1000");
    const cancelTextValue = $(".present_value");
    const overText = $(".over_text");
    const cancelBtn = $(".cancel");
    const cancelBtnAny = $(".cancel_any");
    const cancelBtnSeller = $(".cancel_seller");

    const textLimit = function (e, limitNum) {
      const value = e.target.value; // 入力された文字
      const count = value.length; // 入力された文字数
      cancelTextValue.text(count); // 入力文字数確認用(更新)

      // 出品者キャンセルボタン（必須）の空白送信制御
      if (count === 0) {
        cancelBtnSeller.prop("disabled", true);
        cancelBtnAny.prop("disabled", false);
        overText.removeClass("is_active");
        cancelText.removeClass("is_error");
        cancelTextValue.removeClass("is_active");
        return;
      } else {
        cancelBtnSeller.prop("disabled", false);
      }

      // 指定した文字到達時警告
      if (count > limitNum) {
        overText.addClass("is_active");
        cancelText.addClass("is_error");
        cancelTextValue.addClass("is_active");
        cancelBtn.prop("disabled", true);
      } else {
        overText.removeClass("is_active");
        cancelText.removeClass("is_error");
        cancelTextValue.removeClass("is_active");
        cancelBtn.prop("disabled", false);
      }
    };

    cancelText.on("keydown keyup keypress paste change", (e) => textLimit(e, 140));
    cancelText140.on("keydown keyup keypress paste change", (e) => textLimit(e, 140));
    cancelText1000.on("keydown keyup keypress paste change", (e) => textLimit(e, 1000));

    // 質問コメント
    // 読み込み時に吹き出しの高さを計測。4行以上の高さだったら.is_accordionを付ける。「続きを読む」「閉じる」の対応の為。
    $('.comment_box .commentText').each(function() {
      const height = $(this).height();
      const $comment = $(this).parents('.comment');
      if(height >= 120){ // 高さ120pxを4行とする
        $comment.addClass('is_accordion');
      }
    })

    // 吹き出しの「続きを読む」「閉じる」の対応
    $('.comment_box .comment .more, .comment_box .comment .close').on('click', function() {
      const $comment = $(this).closest('.is_accordion');
      const $commentText = $(this).siblings('.commentText');
      const commentTextheight = $commentText.get(0).scrollHeight;
      // is_activeを付け外し
      $comment.toggleClass('is_active');
      // 吹き出しが開くときのアニメーション用にmax-heightを付け外し
      if($comment.hasClass('is_active')) {
        // is_activeが付いたときの吹き出しのpadding-bottomの値を取得
        const commentTextheightPaddingBottom = Number($commentText.css('paddingBottom').replace(/px/, ''));
        $commentText.css('maxHeight', commentTextheight + commentTextheightPaddingBottom);
      } else {
        $commentText.css('maxHeight', '');
      }
      // 「コメントをもっと見る」ボタンでコメントたちが全数表示になっている時、heightをautoで上書き。吹き出しが広がりきらない為。
      $(this).closest('.overComment.is_active').height('auto');
      // リンク先に飛ばないように止める
      return false;
    });

    // コメント数が多いときに「コメントをもっと見る」ボタンを出して制御
    const $commentListLi = $('.commentList li');
    const commentListLiShowLength = 3; //最初に表示しておくコメント数
    if($commentListLi.length > commentListLiShowLength) {
      $('.commentShowAllBtn_box').show();
    }
    // 多いコメントをclassを付けて隠す
    $commentListLi.each(function(index){
      if(index < commentListLiShowLength ) {
        return true;
      }
      $(this).addClass('overComment');
    })

    // 「コメントをもっと見る」ボタンを押した対応
    $('.commentShowAllBtn_box button').on('click', function() {
      // 隠していたコメントを表示
      const $overComment = $('.commentList .overComment');
      $overComment.toggleClass('is_active');
      // コメントが開くときのアニメーション用にheightを付け外し
      $overComment.each(function(){
        if($(this).hasClass('is_active')){
          const overCommentheight = $(this).get(0).scrollHeight;
          $(this).height(overCommentheight);
        } else {
          $(this).height('');
        }
      })
      // 「コメントをもっと見る」ボタンを入れ替え
      $('.commentShowAllBtn_box').toggleClass('is_active');
    });

    // 商品レビュー「続きを読む」ボタン//
    // 対象テキスト
    const reviewText = $('.js_reviewText');
    // 続きを読むボタン
    const reviewMoreBtn = $('.js_reviewMoreBtn');
    // 設定高さ
    const baseHeight = 100;

    // 「続きを読む」「閉じる」の対応
    $(reviewMoreBtn).click(function() {
      var originalHeight = $(this).siblings(reviewText).css({
        'height': '',
      }).innerHeight();

      let parentElement = $(this).parent();

      parentElement.toggleClass('is_active');
      if($(this).hasClass('is_open')){
        $(this).removeClass('is_open');
        $(this).text('続きを読む');
        parentElement.css({
          'max-height': baseHeight,
          'overflow': 'hidden',
        });
      } else {
        $(this).addClass('is_open');
        $(this).text('閉じる');
        parentElement.css({
          'max-height': originalHeight,
          'overflow': 'visible',
        });
      }
    });
  }

  // レビューテキストの高さを更新
  updateReviewTextHeight(){
    // 対象テキスト
    const reviewText = $('.js_reviewText');
    // 続きを読むボタン
    const reviewMoreBtn = $('.js_reviewMoreBtn');
    // 設定高さ
    const baseHeight = 100;
    reviewText.each(function() {
      // テキスト全文の高さ
      let reviewTextHeight = $(this).height();
      // 設定高さより少ないときはもっと見るボタン非表示
      if(reviewTextHeight <= baseHeight){
        $(this).siblings(reviewMoreBtn).hide();
      } else {
        $(this).addClass('is_accordion');
      }
    });
  }
}
