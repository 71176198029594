import { Application } from "@hotwired/stimulus"

const application = Application.start()

// デフォルトはtrueでTurbo Driveを有効化される
// Turbo.session.drive = false

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

// import $ from 'jquery/dist/jquery'
import $ from 'jquery'
window.jQuery = window.$ = $
