import { Controller } from "@hotwired/stimulus"
import { Collapse } from "bootstrap"
import { put } from "@rails/request.js"

// Connects to data-controller="template-addition"
export default class extends Controller {
  static targets = ['mypageMenu', 'notificationList', 'badge'];

  connect() {
    this.controlHeaderCollapse();
  }

  // 通知リスト・マイページメニューを閉じる
  controlHeaderCollapse() {
    window.onclick = (event) => {
      // 通知リストが開いている場合、通知リスト以外の箇所をクリックすると閉じる
      if (
        this.notificationListTarget.classList.contains("show") &&
        event.target != this.notificationListTarget
      ) {
        const notificationCollapse = Collapse.getInstance(this.notificationListTarget);
        notificationCollapse.hide();
      }

      if (
        this.mypageMenuTarget.classList.contains("show") &&
        event.target != this.mypageMenuTarget
      ) {
        const myMenuCollapse = Collapse.getInstance(this.mypageMenuTarget);
        myMenuCollapse.hide();
      }
    };
  }

  // 通知の既読処理を行って表示を既読にする
  async updateNotificationUnread() {
    // ベルマークに件数が表示されていれば既読の処理を依頼
    if (this.badgeTarget != null && this.badgeTarget.textContent != "") {
      put('/update_unread', {})
      // 件数表示をクリア
      this.badgeTarget.style.display = "none";
      this.badgeTarget.textContent = "";
    }
  }
}
