import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-form"
export default class extends Controller {
  connect() {
    this.textareaValidation('.limit50', 50);
    // this.textareaValidation('.limit100', 100);
    this.textareaValidation('.limit1000', 1000);
    this.cancelPolicyChecked();
    this.cancelPolicyEvent();
    this.anyButtonEvent();
    this.deliveryChargesEvent();
  }

  // テキストエリアの文字制限
  textareaValidation(selector, limitNum) {
    // バリデーションエラー後の再レンダリング時にエラーを表示
    document.querySelectorAll(selector).forEach(element => this.textareaError(element, limitNum));
    // イベント発火
    $(selector).on("keydown keyup keypress paste change", (e) => this.textareaEvent(e, limitNum));
  }

  // 出品画面ーキャンセルポリシー
  cancelPolicyEvent() {
    $('input[name="furima_product[is_base_policy]"]').on('change', function () {
      // 全てのクラスを外す
      $('.js_cancelPolicy_list li').removeClass('is_current');
      // checked状態かどうか判断
      if($(this).is(':checked')){
        // TRUE:枠線用クラス追加
        $(this).parents('li').addClass('is_current');
      };
      // キャンセルポリシー入力欄の表示非表示
      if ($('.js_originPolicy').prop('checked')) {
        $('.js_cancelPolicy_textArea').slideDown();
      } else {
        $('.js_cancelPolicy_textArea').slideUp();
      }
    });
  }

  // キャンセルポリシー入力欄の表示非表示
  cancelPolicyChecked() {
    if ($('.js_originPolicy').prop('checked')) {
      $('.js_cancelPolicy_textArea').slideDown();
    } else {
      $('.js_cancelPolicy_textArea').slideUp();
    }
  }

  textareaEvent(elem, limitNUm) {
    const element = elem.currentTarget;
    this.textareaError(element, limitNUm);
  }

  // テキストエリアのバリデーション
  textareaError(element, limitNUm) {
    element.parentNode.previousElementSibling.querySelector(".form_num").textContent = limitNUm - element.value.length;
    // 10文字以内は色変更・太字
    if (Number(element.parentNode.previousElementSibling.querySelector(".form_num").textContent) < 10) {
      element.parentNode.previousElementSibling.querySelector(".form_num").style.color = "#e35768";
      element.parentNode.previousElementSibling.querySelector(".form_num").style.fontWeight = "bold";
    } else {
      element.parentNode.previousElementSibling.querySelector(".form_num").style.color = "#444444";
      element.parentNode.previousElementSibling.querySelector(".form_num").style.fontWeight = "normal";
    }

    // 制限文字数に達したらエラーメッセージ
    if (element.value.length > limitNUm) {
      element.classList.add("is_error");
    } else {
      element.classList.remove("is_error");
    }
  }

  // 任意項目の開閉
  anyButtonEvent() {
    const anyBtn = $("#any_btn");
    const anyArea = $(".sell_any");

    anyArea.hide();

    // 任意項目に入力エラーがある場合は最初から表示しておく
    if (anyArea.hasClass("openAnyArea")) {
      anyArea.fadeToggle();
      anyBtn.toggleClass("is_open");
      anyBtn.html(`任意項目を閉じる<i class="fa-solid fa-chevron-up"></i>`);
    }

    // ボタンをクリックしたら切替え
    anyBtn.on("click", function () {
      anyArea.fadeToggle();
      anyBtn.toggleClass("is_open");

      if (anyBtn.hasClass("is_open")) {
        anyBtn.html(`任意項目を閉じる<i class="fa-solid fa-chevron-up"></i>`);
      } else {
        anyBtn.html(`任意項目を開く<i class="fa-solid fa-chevron-down"></i>`);
      }
    });
  }

  // 地域別送料設定フォームの初期表示
  deliveryChargesEvent() {
    const optionArea = $(".js_optionArea");

    // 初期表示で閉じる
    optionArea.hide();

    // 送料設定エリアの開閉
    this.toggleDeliveryCharges();
  }

  // 送料設定エリアの開閉
  toggleDeliveryCharges() {
    const optionArea = $(".js_optionArea");
    // 全てのクラスを外す
    $('.js_postage_list li').removeClass('is_current');
    // 商品価格をアクティブに
    $('.js_input_price').removeClass('is_disable');

    // checked状態かどうか判断
    let target = event ? $(event.target) : $('.js_postage_list input:checked');
    target.parents('li').addClass('is_current');

    // 基本送料と地域別送料の入力欄を表示非表示
    if ($('.js_postageNotInclude').prop('checked')) {
      optionArea.slideDown()
    } else {
      optionArea.slideUp()
    }
  }
}
