import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="address-form"
export default class extends Controller {
  static targets = ['addressId', 'phoneNumber', 'customToken']

  connect() {
    this.setSelectedAddressId()
    this.fetchCustomToken()
  }

  // 注文フォームで選択中のお届け先IDをお届け先モーダルのフォームにセットする
  // Railsへ送信し、届け先リストを再描画するときにチェック状態を復元する
  setSelectedAddressId() {
    const checkedAddressId = document.querySelector('.address_id:checked')
    if(!checkedAddressId) return
    this.addressIdTarget.value = checkedAddressId.value
  }

  // 郵便番号検索 ※テンプレート側で AjaxZip3 を読み込んでいること
  zipSearch() {
    // 郵便番号を最低限のチェック
    const zip = $('#furima_user_address_zip').val()
    if (zip === '' || !zip.match("^[0-9]{7}$") ) {
      $('#zipError').addClass('is_active')
      return
    }
    // 検索
    AjaxZip3.zip2addr('furima_user_address[zip]','','furima_user_address[prefecture_code]','furima_user_address[city_block]')

    //成功時に実行する処理
    AjaxZip3.onSuccess = function() {
      $('#zipBlankError').removeClass('is_active')
      $('#zipError').removeClass('is_active')
    };

    //失敗時に実行する処理
    // NOTE: AjaxZip3側で郵便番号の頭３桁に該当するファイルが存在しないと404になり、キャッチできない e.g.) 0000000 など
    // 404でキャッチできないケースはRails側のフォーマットチェックである程度拾ってバリデーションエラーとする
    AjaxZip3.onFailure = function() {
      $('#zipError').addClass('is_active')
    }
  }

  // お届け先登録・更新フォームを開いたタイミングでカスタムトークンを取得し、フォームにセットしておく
  // Rails側で電話番号が空の場合はこのカスタムトークンを使ってシティの電話番号を取得する
  async fetchCustomToken() {
    const result = await fetch(`${gon.global.libecityUrl}/identityService/customToken`, {
      mode: "cors",
      credentials: "include"
    })
    const json = await result.json()
    this.customTokenTarget.value = json.custom_token || ""
  }
}
