import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-top"
export default class extends Controller {

  // バリデーションエラーや確認画面の表示を turbo で書き換えた場合に、画面上部へスクロールするだけ
  connect() {
    this.scrollTop();
  }

  scrollTop() {
    let top = 0
    // エラーの有無をチェック
    if ($('.txt_error.is_active')[0]) {
      top = $('.txt_error.is_active:first').parent().prev().offset().top
      window.scroll({top: top, behavior: 'smooth'});
    }else{
      // 通常の画面遷移では即時画面トップを表示
      window.scroll({top: top, behavior: 'instant'});
    }
  }
}
