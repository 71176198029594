import { Controller } from "@hotwired/stimulus"

// スクロール時にヘッダーを隠す
// Connects to data-controller="header-hide-in-scroll"
export default class extends Controller {
  static values = {
    startPos: Number, // 現在位置を初期値０で定義（HTML側では定義しない）
    isHide: String    // "on" を付与した場合のみスクロール時に非表示する
  }

  connect() {
    if (this.isHideValue == 'on') {
      window.addEventListener("scroll", this.hideInScroll);
    } else {
      // ヘッダー表示状態で呼び出された場合を考慮して一旦表示状態にしてからイベントリスナーを解除
      const headerContainer = document.getElementById("content_mainNav");
      headerContainer.classList.remove("is_hide");
      window.removeEventListener('scroll', this.hideInScroll);
    }
  }

  hideInScroll() {
    const headerContainer = document.getElementById("content_mainNav");
    let winScrollTop = 0;

    // スクロール量の取得 iPhoneスクロールバインド対策
    winScrollTop = window.scrollY || window.pageYOffset;

    if (winScrollTop >= this.startPosValue && winScrollTop > 100) {
      // 下にスクロールしたとき
      headerContainer.classList.add("is_hide");
    } else {
      // 上にスクロールしたとき
      headerContainer.classList.remove("is_hide");
    }
    // 現在位置を更新
    this.startPosValue = winScrollTop;
  }
}
