import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="seller-listing-form-category"
export default class extends Controller {
  static targets = ["categoryId", "handmadeAttention"];
  static values = { handmadeId: String };

  connect() { }

  toggleHandmadeAttention() {
    if (this.categoryIdTarget.value === this.handmadeIdValue) {
      this.handmadeAttentionTarget.style.display = "block";
    } else {
      this.handmadeAttentionTarget.style.display = "none";
    }
  }
}
