import { Controller } from "@hotwired/stimulus"

// テキストエリアのバリデーション（入力文字数あり）
// 設置例
//   <textarea class="textarea_count" id="transaction_message" data-limit="1000" data-action="validation--textarea-count#checkTextarea" data-disabled-item="submit_btn"></textarea>
//     - data-limit="1000" 最大文字数を指定
//     - class="textarea_count" クラス名は固定 textarea_count
//     - id属性の指定は必須
//     - data-disabled-item="submit_btn" テキストエリアの入力の有無によって disabledを付与するフォームのIDを指定（任意）
//
//    文字数カウント表示部にtargetを指定してください
//    <span data-validation--textarea-count-target="inputCount" class="present_value">0</span>/1000文字

// Connects to data-controller="validation--textarea-count"
export default class extends Controller {
  static targets = ["inputCount"];

  connect() {
    this.initUpdateTextCount('.textarea_count');
  }

  initUpdateTextCount(selector) {
    document.querySelectorAll(selector).forEach(element => this.updateTextCount(element));
  }

  checkTextarea(event) {
    const element = document.getElementById(event.target.attributes.id.value);
    this.updateTextCount(element);
  }

  updateTextCount(element) {
    const limitNum = element.dataset.limit; // 最大文字数
    const count = element.value.length; // 入力された文字数

    // 現在の文字数を更新
    this.inputCountTarget.textContent = count;

    // 文字数チェック
    if (count > limitNum) {
      element.classList.add('is_error');
    } else {
      element.classList.remove('is_error');
    }

    this.updateDisabledItem(element, count === 0);
  }

  // 活性・非活性切り替え対象IDが設定されている場合のみ、入力の有無でボタン等のフォームを制御
  updateDisabledItem(element, disabled) {
    const disabledItemId = element.dataset.disabledItem;
    if (disabledItemId) {
      const disabledItem = document.getElementById(disabledItemId);
      disabledItem.disabled = disabled;
    }
  }
}
